
import { defineComponent, onMounted } from "vue";
import KTTableWidgetSummaryStock from "@/components/widgets/tables/WidgetSummaryStock.vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";

export default defineComponent({
  name: "daily-cash-flow",
  components: {
    KTTableWidgetSummaryStock,
  },
  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbs("Summary Stock", ["Financial Statements","Stock Card"]);
    });
  },
});
